// import ReactDOM from "react-dom";
import Link from 'next/link'
import { route } from 'themes/route'
import Layout from 'themes/layouts/Home'
import { DatePicker, message } from 'antd';
import { Button, Row, Col, Typography} from 'antd';
import { useState } from "react";
import useBaseHook from 'themes/hooks/BaseHooks'
const { Title } = Typography
// import { bounce } from 'react-animations';
// const [hoverRef, isHovered] = useHover();

const Index = () => {
  const {t} = useBaseHook()
  return <Layout>
  <div id="bannerHeaderHome">
    <div className="container">
      <Row>
        <Col lg={12} xl={12}>
        <div className="contentTextInfoHeader">
              <h1>{t("home.index.titleH1")}</h1>
          <h2>{t("home.index.titleH2")}</h2>
          {/* <Button className="resAccountMqn">{t("home.index.createAccount")}</Button> */}
          <a href="/blog">
            <Button className="contactSale">{t("home.index.contactSale")}</Button>
          </a>
        </div>
        </Col>
        <Col lg={12} xl={12}>
        <div className="imgHeader">
          <img src="/static/img/intro-animation.png" alt="dohu.vn" />
        </div>
        </Col>
      </Row>
    </div>
  </div>

  <div id="backgroundVideoMqnMain">
    <div className="container">
      <Row>
        <div className="videoMainDetail">
            <Col xl={{ span: 24, offset: 3 }}>
              <div className="titleVideo">
                <h1>{t("home.index.introductionVideo")}</h1>
              </div>
              <div className="videoMarketing">
                <img src="/static/img/image_2024_11_21T13_00_20_171Z.png" style={{width: '100%'}}/>
                {/* <video loop={true} controls muted>
                  <source src="/static/video/CAMPSITE_EN_June2018.mp4" type="video/mp4"/>
                </video> */}
              </div>
            </Col>
            {/* <img src="/static/img/CAMPSITEMQN.png" alt="dohu.vn"/> */}
        </div>
      </Row>
    </div>
  </div>

  <div id="bannerItemShowInfoMqn">
    <div className="container">
     <div className="titleFeatureIndex">
       <h3><span className="lineTop"></span> {t("home.index.features")}</h3>
       <h1>{t("home.index.featureTitle")}</h1>
       <p>{t("home.index.featureDescription")}</p>
     </div>
      <Row gutter={24}>
        <Col lg={12} xl={12}>
        <div className="itemImgBannerShow">
          <img src="/static/img/using-software.png" alt="dohu.vn" />
        </div>
        </Col>
        <Col lg={12} xl={12}>
        <div className="contentImgBannerShow">
          <h1>{t("home.index.feature1Title")}</h1>
          <img src="/static/img/iconLine.PNG" alt="dohu.vn" />
          <p>{t("home.index.feature1Description")}</p>
            {/* <Button className="btnShowMore">{t("home.index.feature1Button")}</Button> */}
        </div>
        </Col>
      </Row>
      <div className="itemArrow">
        <img src="/static/img/section-divider-bg-left.png" alt="dohu.vn" />
      </div>
      <Row gutter={24}>
        <Col lg={12} xl={12}>
        <div className="contentImgBannerShow">
          <h1>{t("home.index.feature2Title")}</h1>
          <img src="/static/img/iconLine.PNG" alt="dohu.vn" />
          <p>{t("home.index.feature2Description")}</p>
          {/* <Button className="btnShowMore">{t("home.index.feature2Button")}</Button> */}
        </div>
        </Col>
        <Col lg={12} xl={12}>
        <div className="itemImgBannerShow">
          <img src="/static/img/businessman-with-laptop.png" alt="dohu.vn" />
        </div>
        </Col>
      </Row>
      <div className="itemArrow">
        <img src="/static/img/section-divider-bg-right.png" alt="dohu.vn" />
      </div>
      <Row gutter={24}>
        <Col lg={12} xl={12}>
        <div className="itemImgBannerShow">
          <img src="/static/img/lady-with-handphone.png" alt="dohu.vn" />
        </div>
        </Col>
        <Col lg={12} xl={12}>
        <div className="contentImgBannerShow">
          <h1>{t("home.index.feature3Title")}</h1>
          <img src="/static/img/iconLine.PNG" alt="dohu.vn" />
          <p>{t("home.index.feature3Description")}</p>
          {/* <Button className="btnShowMore">{t("home.index.feature3Button")}</Button> */}
        </div>
        </Col>
      </Row>
    </div>
  </div>

</Layout>
}
export default Index